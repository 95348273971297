import React, { useState } from 'react';
import Layout from '../components/layout';
import Box, { BoxTitle, BoxParagraph, Bibliobox, BoxImage} from '../elements/box';
import Card, { CardImage } from '../elements/card';

function La_Systemie() {

  const cycle = [
    <Box background="primary-light">
      <BoxParagraph>
        <p className="title is-6 is-family-secondary">L’époque des rituels de séduction :</p>
        <p>A la fin de l’adolescence, le jeune rencontre une période déterminée, heureusement relativement longue, pendant 
          laquelle il doit prendre sa place dans la société, se situer par rapport aux autres et se choisir un compagnon ou une 
          compagne.</p>
        <p>En ce qui concerne les rituels de séduction, il y a un facteur temps et un facteur risque. Plus le jeune tarde à 
          adopter ce comportement, plus il risque d’être marginal. Le poids de la famille est aussi très important dans le choix 
          d’un partenaire.</p>
      </BoxParagraph>
    </Box>,
    <Box background="primary-light">
      <BoxParagraph>
        <p className="title is-6 is-family-secondary">Le mariage et ses conséquences :</p>
        <p>La cérémonie du mariage est très importante, non seulement pour le jeune couple, mais aussi pour toute la famille, 
          car elle change la nature des relations.</p>
        <p>Ce que l’on attend du mariage est souvent une illusion fort éloignée de la réalité. En général, le mariage consiste 
          en un accord entre deux jeunes gens qui prennent l’un envers l’autre un engagement pour la vie.</p>
        <p>Quand le couple débute dans sa vie commune, les deux conjoints doivent mettre au point un certain nombre d’accords 
          que l’intimité de deux personnes vivant ensemble rend indispensables. Ils doivent aussi apprendre à régler leurs 
          différends. Au début, ça se présente sous la forme de disputes, puis avec le temps, ils mettent au point des moyens 
          de régler les désaccords et de résoudre les problèmes. Individuellement, il faut passer d’un état de dépendance 
          vis-à-vis de ses propres parents à une relation d’adulte indépendant et se comporter différemment envers ceux-ci.</p>
        <p>L’art, pour un jeune couple, consiste donc à faire respecter son indépendance tout en maintenant des liens affectifs 
          avec ses proches.</p>
      </BoxParagraph>
    </Box>,
    <Box background="primary-light">
    <BoxParagraph>
      <p className="title is-6 is-family-secondary">La naissance des enfants et l’art de les élever :</p>
      <p>La naissance d’un enfant remet en cause un très grand nombre d’accommodements divers qui avaient cours jusqu’alors dans 
        le système familial. L’effet consiste en une relation triangulaire. Les difficultés que rencontre le couple sont alors 
        vécues par le biais de l’enfant, lequel devient bouc émissaire. </p>
      <p>La naissance d’un enfant entraîne la rencontre de deux familles, ce qui implique de nouvelles négociations, de nouvelles 
        querelles. L’autonomie du jeune couple est accentuée par rapport à la famille, mais son engagement dans le système 
        familial est renforcé.</p>
      <p>Le plaisir d’élever des enfants est souvent contrebalancé par le stress dû à la complexité des problèmes dans lesquels 
        les parents sont perpétuellement plongés. La jeune mère peut être frustrée car coupée du monde des adultes.</p>
      <p>Une crise peut survenir dans des familles en proie à des difficultés, lorsque le jeune enfant doit aller à l’école. Les 
        conflits opposant les parents sur la manière d’élever les enfants deviennent plus évidents lorsque le résultat de leur 
        éducation est exposé aux regards. Durant cette période, le problème le plus souvent rencontré, se pose lorsque l’un des 
        parents se ligue régulièrement avec un enfant contre l’autre parent. Dans les familles monoparentales, cette triangulation 
        peut se faire avec l’un des grands-parents, la grand-mère par exemple.</p>
    </BoxParagraph>
  </Box>,
  <Box background="primary-light">
  <BoxParagraph>
    <p className="title is-6 is-family-secondary">Les difficultés conjugales de l’époque de la maturité</p>
    <p>Au fur et à mesure que les enfants grandissent, les relations se modifient au sein de la famille, ce qui entraîne une 
      perpétuelle remise en question des relations entre mari et femme qui, dans ce cycle de leur existence, parviennent à 
      leurs années de maturité.</p>
    <p>Les relations s’approfondissent et s’enrichissent et il existe des rapports stables avec la famille et un cercle d’amis 
      proches. Les conflits surviennent en cas de trop grande ou de non suffisante réussite professionnelle de l'un des conjoints.
      Il en est de même si l'autre éprouve le besoin d’introduire des changements dans son existence, comme par exemple de 
      reprendre une activité professionnelle.</p>
    <p>Durant cette période, le couple peut être amené à décider de rester uni ou de se séparer. Un enfant ou l’un des parents 
      peut manifester des symptômes dans le but de stabiliser le système.</p>
  </BoxParagraph>
</Box>,
<Box background="primary-light">
      <BoxParagraph>
        <p className="title is-6 is-family-secondary">Comment sevrer les parents de leurs enfants ?</p>
        <p>Toutes les familles entrent dans une période de crise lorsque les enfants commencent à avoir des activités à 
          l’extérieur. Le couple franchit cette étape avec succès lorsqu’il parvient à résoudre ces conflits et à faire en 
          sorte de permettre aux enfants de choisir seuls leurs partenaires et leur métier.</p>
        <p>C’est la transition nécessaire pour se séparer de leurs enfants et pouvoir devenir des grands-parents. Si ça ne se 
          passe pas bien, les parents peuvent découvrir qu’ils n’ont rien à se dire et rien à partager car, depuis des années, 
          ils ne parlaient de rien sauf des enfants. Le conflit peut se terminer par une séparation ou un divorce.</p>
        <p>La schizophrénie d’un enfant pourrait être un moyen extrême utilisé pour tenter de résoudre ses problèmes, 
          l’enfant devant rester à la maison et les parents continuer de s’occuper de lui. </p>
        <p>En définitive, les parents doivent apprendre l’art d’être de bons grands-parents et organiser harmonieusement leur 
          existence dans une maisonnée où ils ne sont plus que tous les deux.</p>
      </BoxParagraph>
    </Box>,
    <Box background="primary-light">
    <BoxParagraph>
      <p className="title is-6 is-family-secondary">La retraite et la vieillesse :</p>
      <p>A l’heure de la retraite, les conjoints se retrouvent pratiquement ensemble 24 heures sur 24. Celui qui cesse son 
        activité professionnelle peut se sentir tout à coup inutile, l’autre peut devenir malade pour lui donner une utilité.</p>
      <p>Lorsque le couple devient très âgé, la famille doit affronter le terrible dilemme de s’occuper du vieillard ou de 
        lui trouver un établissement. </p>
    </BoxParagraph>
  </Box>
  ];

  const longueur = cycle.length;

  const [elementId, setElementId] = useState(0);

  return(
    <Layout>
      <Box background="primary-light">
        <BoxTitle title="L’intervention systémique" />
        <BoxParagraph>
          <p>Cette approche s'inscrit dans le courant des théories systémiques. Elle porte son regard sur l'inter - action plutôt 
            que sur l'intra – psychique, sur le comment plutôt que le pourquoi. Elle considère le symptôme comme l'expression 
            d'une difficulté d'ajustement à l'intérieur du système, et / ou avec l'environnement. Elle permet donc un regard 
            particulièrement ajusté aux champs institutionnels, éducatifs, sanitaires et sociaux.</p>
          <p>La thérapie systémique est largement issue des recherches de l’école de Palo Alto en Californie, dans les années 
            1950-1970 et particulièrement des travaux de Gregory Bateson, Paul Watzlawick, Don Jackson, Jay Haley, Richard 
            Weackland, portant notamment sur la communication et le changement.</p>
          <p>Elle a également été influencée par deux grands courants de la psychanalyse : le premier étant celui de la 
            psychanalyse d’enfant avec la prise en compte des relations familiales (Anna Freud) et le second, celui de 
            l’orientation culturaliste de la psychanalyse post-freudienne (Fromm, Sullivan).</p>
          <p>Cette méthode de psychothérapie est fondée sur la notion de système considéré comme un « ensemble d’éléments en 
            interaction dans la poursuite d’une ou plusieurs finalités spécifiques ». Une cellule, une personne, une famille une 
            entreprise constituent autant de systèmes.</p>
          <p>Le psychothérapeute systémique ne s’attache donc pas tant à rechercher dans l’histoire, le passé et les processus 
            intrapsychiques du patient les causes de ses dysfonctionnements actuels qu’à susciter un changement dans « l’ici et 
            maintenant » étant entendu que tout changement (portant par exemple sur un comportement précis) entraîne un ensemble 
            de modifications en chaîne.</p>
          <p>La psychothérapie familiale, dont Mony Elkaïm est un des principaux représentants, constitue aujourd'hui une des 
            applications les plus répandues de ces principes.</p>
          <p>La famille est considérée comme une unité, un système et le patient comme le porteur de symptômes du système familial. 
            Il s’agit alors d’appréhender la fonction du symptôme à l’intérieur du groupe, de comprendre en quoi, notamment, il protège 
            son équilibre global (place de chacun, répartition des rôles et des tâches, existence de sous systèmes, frontière entre 
            générations…).</p>
          <p>Le processus psychothérapeutique vise à rompre avec ce cercle vicieux. Dans cet objectif, la psychothérapie systémique 
            insiste donc sur l’importance des règles qui régissent les interactions des membres de la famille afin d’agir dessus pour 
            les modifier.</p>
          <p>Le psychothérapeute est actif et peut proposer des actions sous la forme d’injonctions thérapeutiques, de 
            prescriptions, de tâches comportementales, de recadrages visant à agir et à modifier la perception d’une situation. 
            Le processus se déroule sous la forme d’entretiens collectifs impliquant souvent deux thérapeutes et un nombre de 
            séances fixé au départ.</p>
          <p><em>(article de Benoît Mélet, FF2P)</em></p>
        </BoxParagraph>
      </Box>
      <Box>
        <BoxTitle title="Le cycle de la vie familiale" />
        <BoxParagraph>
          <p>Les familles évoluent au cours du temps selon un processus dont les perturbations entraînent, chez les êtres humains 
            qui constituent ces familles, l’apparition de bouleversements et de symptômes psychiatriques.</p>
        </BoxParagraph>
        <div className="columns">
          <div className="column is-3">
            <Card height="">
              <CardImage src="illustration8" alt="illustration"/>
            </Card>
          </div>
          <div className="column">
            <BoxParagraph>
              <p className="title is-5 is-family-secondary">Principaux cycles de vie :</p>
              <p>Un système familial peut en effet vivre différents stades de développement, différents cycles de vie : la formation 
              du couple, la naissance des enfants, la période de l’adolescence, le départ des enfants, le vieillissement...</p>
              <p>Chaque nouvelle étape demande des changements au sein des relations entre les membres.  Des réajustements doivent être 
                faits.</p>
              <p>Certains systèmes plus rigides vivent difficilement ces périodes de transition et ne trouvent pas une réponse adéquate 
                face à l’exigence de changements.  C’est souvent à ce moment que des symptômes peuvent apparaître chez un des membres de 
                la famille.</p>
            </BoxParagraph>
          </div>
        </div>
        <div className="container">
          <content className="content">
            <div className="columns">
              <button className="column button is-primary is-light is-small is-fullwidth is-rounded" onClick= {() => {
                console.log(elementId);
                setElementId((elementId === 0 ? (longueur - 1) : elementId - 1)); }
              } >
                <span className="icon">
                    <i className="fas fa-arrow-left fa-3x is-flex"></i>
                </span>
              </button>
              {cycle[elementId]}
              <button className="column button is-primary is-light is-small is-fullwidth is-rounded" onClick={ () => {
                setElementId((elementId === (longueur - 1) ? 0 : elementId + 1)); }
              } >
                <span className="icon">
                    <i className="fas fa-arrow-right fa-3x is-flex"></i>
                </span>
              </button>
            </div>
          </content>
        </div>
      </Box>
      <Box>
        <BoxTitle title="Bibliographie :" />
          <p className="title is-5 is-family-secondary">Sur les couples :</p>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="Un et un font trois"
                references="Philippe CAILLÉ – Editions Fabert "
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Crises de couple  – Cahiers critiques de thérapie familiale et de pratique de réseaux"
                references="Boeck Université"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="L'irrationnel dans le couple et la famille"
                references="Robert NEUBURGER – ESF"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Le mythe familial"
                references="Robert NEUBURGER – ESF"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="Nouveaux couples"
                references="Robert NEUBURGER – Odile Jacob"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Thérapie du couple et de la famille"
                references="Virginia SATIR – Desclée de Brouwer"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Les objets flottants"
                references="Philippe CAILLÉ et Yveline REY – Editions Fabert "
              />
            </div>
            <div className="column">
              <Bibliobox
                title="L'approche contextuelle"
                references="Guenièvre SHAMS AJILI et Pierre MICHARD – Essentialis "
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <Bibliobox
                title="Du côté de chez soi"
                references="Magda HEIREMAN – ESF"
              />
            </div>
            <div className="column is-one-quarter">
              <Bibliobox
                title="Le casse-tête conjugal"
                references="Paul NORMAN – Erès "
              />
            </div>
            <div className="column is-one-quarter">
              <Bibliobox
                title="Vivre sans violences ?"
                references="Michel SILVESTRE et Micheline CHRISTEN – Erès"
              />
            </div>
          </div>

          <p className="title is-5 is-family-secondary">Hypnose Ericksonienne et PNL</p>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="Les secrets de la communication"
                references="BANDLER R, GRINDER J - PUF, 1994"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="L'hypnose thérapeutique"
                references="ERICKSON M.H - ESF, Paris, 1986"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Ma voix t'accompagnera"
                references="ERICKSON M.H - Hommes et groupes, Paris, 1986"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Milton Erickson : un thérapeute hors du commun"
                references="HALEY J. - EPI, Paris, 1984"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <Bibliobox
                title="La métaphore thérapeutique et ses contes, Etudes Ericksoniennes"
                references="KEROUAC M. - MKR Editions, Canada, 1996"
              />
            </div>
          </div>

          <p className="title is-5 is-family-secondary">Introduction à la pensée Sytémique<br />
            (Théorie des systèmes, de la communication, de la complexité)</p>
            <div className="columns">
            <div className="column">
              <Bibliobox
                title="Vers une écologie de l'esprit, 2 tomes"
                references="BATESON G - Seuil, Paris, 1977"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="La nature et la pensée"
                references="BATESON G - Seuil, 1984"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Communication et société"
                references="BATESON G, RUESCH J - Seuil, 1988"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Théorie Générale des Systèmes"
                references="BERTALANFFY L.V. - Dunod, Paris, 1973"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="Des robots, des esprits et des hommes"
                references="BERTALANFFY L.V. - ESF, Paris, 1982"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Deux contre un"
                references="CAPLOW T. - ESF, Paris, 1984"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="La systémique"
                references="DURAND D. - PUF, Que sais-je?, 1979"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Le macroscope"
                references="DE ROSNAY J - Seuil, Paris, 1975"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="L’analyse des systèmes, l’application aux sciences sociales"
                references="LAPIERRE J.W. - Syros, Paris, 1992"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="La modélisation des systèmes complexes"
                references="LE MOIGNE J.L - PUF, Paris, 1990"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="La Théorie du système général"
                references="LE MOIGNE J.L - PUF, Paris, 1977"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Les systèmes de décision dans les organisations"
                references="LE MOIGNE J.L - PUF, Paris, 1974"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Bibliobox
                title="Les systèmes d'information dans les organisations"
                references="LE MOIGNE J.L - PUF, Paris, 1974"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="La systémique sociale"
                references="LUGAN J.C. - PUF, Que sais-je ?, 1993 "
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Introduction à la pensée complexe"
                references="MORIN E. - ESF, Paris, 1990"
              />
            </div>
            <div className="column">
              <Bibliobox
                title="Grégory Bateson, itinéraire d'un chercheur"
                references="PAUZE R. - Erès, Paris, 1996"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <Bibliobox
                title="Systèmes et modèles, Introduction critique à l’analyse de systèmes"
                references="WALLISER B. - Seuil, 1977"
              />
            </div>
            <div className="column is-one-quarter">
              <Bibliobox
                title="La réalité de la réalité"
                references="WATZLAWICK P. - Seuil, Paris, 1978"
              />
            </div>
            <div className="column is-one-quarter">
              <Bibliobox
                title="L'invention de la réalité"
                references="WATZLAWICK P. (sous la direction de) - Seuil, Paris, 1988"
              />
            </div>
            <div className="column is-one-quarter">
                <BoxImage src="zebre" alt="zebre" size="is-128x128" />
            </div>
          </div>         
      </Box>
    </Layout>
  )
}

export default La_Systemie;